import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
export interface EnderecoCep {
    cep: string;
    logradouro: string;
    complemento?: string;
    bairro: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
}

@Injectable({
    providedIn: 'root'
  })
export class BuscarCEPService {
    constructor(private http: HttpClient) { }

    private criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento: boolean): { headers: HttpHeaders } {
        const headers = new HttpHeaders().set('X-Show-Loading-Animation', mostrarAnimacaoDeCarregamento.toString());
        return { headers };
    }

    public consultaCEP(cep: string, mostrarAnimacaoDeCarregamento = true):Observable<EnderecoCep | { erro: true }> {
      cep = cep?.replace(/\D/g, '');
      if (cep !== '') {
        const validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
            const opcoes = this.criarOpcoesDaRequisicao(mostrarAnimacaoDeCarregamento);
            return this.http.get<EnderecoCep>(`https://viacep.com.br/ws/${cep}/json`, opcoes)
        }
      }
  
      return { erro: true } as any;
    }
}